import React from 'react'
import { cn, buildImageObj } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'
import { Link } from 'gatsby'
import localize from './localize'
import style from './featured-release.module.css'
import { responsiveTitle2 } from './typography.module.css'

const FeaturedRelease = ({ title, excerpt, cta, image }) => {
  return (
    <div className={style.wrapper}>
      <div className={style.copy}>
        {title && <h2 className={cn(responsiveTitle2, style.title)}>{title}</h2>}
        {excerpt && <div className={style.excerpt}>{excerpt}</div>}
        {cta && (
          <Link className={style.cta} to={cta.route}>
            {cta.title}
          </Link>
        )}
      </div>
      {image && image.asset && (
        <img
          className={style.img}
          src={imageUrlFor(buildImageObj(image))
            .width(300)
            // .height(Math.floor((9 / 16) * 600))
            .url()}
          alt={image.alt}
        />
      )}
    </div>
  )
}

export default localize(FeaturedRelease, { propsTotranslate: ['title', 'excerpt', 'cta', 'image'] })
