import React, { useState } from 'react'
import { graphql } from 'gatsby'
// import { mapEdgesToNodes, filterOutDocsWithoutSlugs } from '../lib/helpers'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import LayoutContainer from '../components/layout-container'
import Layout from '../containers/layout'
import FeaturedRelease from '../components/featured-release'

export const query = graphql`
  query IndexPageQueryEN {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    home: allSanityHomepage(limit: 1) {
      edges {
        node {
          excerpt {
            es
            en
            _type
          }
          title {
            es
            en
            _type
          }
          mainImage {
            alt
            asset {
              _id
            }
          }
          ctaHero {
            _type
            kind
            title {
              en
              es
              _type
            }
            route {
              es
              en
              _type
            }
            link {
              es
              _type
              en
            }
          }
        }
      }
    }
  }
`

const IndexPage = props => {
  const { data, errors } = props
  const [language, setLanguage] = useState('en')
  console.log('preview', data)

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const site = (data || {}).site
  const home = (data || {}).home.edges[0].node
  // const previewReferences = (data || {}).preview.edges[0].node.previewItemNew
  // const previewReferences = (data || {}).preview.edges[0].node.previewItemNew
  // const previewReferences = (data || {}).preview ? mapEdgesToNodes(data.preview) : []
  // console.log(previewReferences)
  // ? mapEdgesToNodes(data.preview).filter(filterOutDocsWithoutSlugs)
  // : []
  console.log(home)

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }

  return (
    <LayoutContainer locale="en">
      <SEO title={site.title} description={site.description} keywords={site.keywords} lang="en" />
      <Container>
        <h1 hidden>Welcome to {site.title}</h1>
        {home && (
          <section>
            <FeaturedRelease
              title={home.title}
              excerpt={home.excerpt}
              cta={home.ctaHero}
              image={home.mainImage}
              locale="en"
            />
          </section>
        )}
        <section>
          <h2>Tour</h2>
          <p>Coming soon...</p>
        </section>
        <section>
          <h2>News</h2>
          <p>Coming soon...</p>
        </section>
      </Container>
    </LayoutContainer>
  )
}

export default IndexPage
