import { MdSettings } from "react-icons/md";

export default {
  name: "siteSettings",
  title: "Site Settings",
  type: "document",
  // You probably want to uncomment the next line once you've made a siteSettings document in the Studio. This will remove the settings document type from the create-menus.
  // __experimental_actions: ['update', 'publish', /* 'create', 'delete' */],
  icon: MdSettings,
  fieldsets: [{ name: "social", title: "Social media accounts" }],
  fields: [
    {
      name: "title",
      title: "Title",
      type: "string"
    },
    {
      name: "logo",
      title: "Logo",
      type: "image"
    },
    {
      name: "description",
      title: "Description",
      type: "text"
    },
    {
      name: "keywords",
      title: "Keywords",
      type: "array",
      of: [{ type: "string" }],
      options: {
        layout: "tags"
      }
    },
    {
      name: "author",
      title: "Author",
      type: "string"
    },
    {
      title: "BandCamp",
      name: "bandcamp",
      type: "string",
      fieldset: "social"
    },
    {
      title: "Youtube",
      name: "youtube",
      type: "string",
      fieldset: "social"
    },
    {
      title: "Instagram",
      name: "instagram",
      type: "string",
      fieldset: "social"
    },
    {
      title: "Facebook",
      name: "facebook",
      type: "string",
      fieldset: "social"
    },
    {
      title: "Open graph",
      name: "openGraph",
      description: "These will be the default meta tags on all pages that have not set their own",
      type: "openGraph"
    }
  ]
};
