import React from 'react'
// import getLocalizedContent from '../lib/create-locale'
import LocaleContext from '../lib/locale-context'
import Layout from '../containers/layout'
import { createLocaleTextGetter } from '../lib/create-locale'
import { graphql, useStaticQuery } from 'gatsby'
import siteSettings from '../../../studio/schemas/siteSettings'

const query = graphql`
  query NavigationMainMenuQuery {
    navigation: sanityNavigationMenu(title: { eq: "MainNav" }) {
      _rawMenuItem
      id
      menuItem {
        menuTitle {
          es
          en
          _type
        }
        menuSlug {
          es {
            current
          }
          en {
            current
          }
          _type
        }
      }
    }
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      logo {
        asset {
          _id
        }
      }
    }
  }
`

const LayoutContainer = ({ locale, ...rest }) => {
  const { navigation, site } = useStaticQuery(query)

  const getLocalizedContent = createLocaleTextGetter(locale)
  return (
    <LocaleContext.Provider value={{ language: locale }}>
      <Layout
        navigation={getLocalizedContent(navigation._rawMenuItem)}
        {...{
          ...rest,
          logo: site.logo,

          homeLink: locale,
          navigation: getLocalizedContent(navigation._rawMenuItem)
        }}
      />
    </LocaleContext.Provider>
  )
}
export default LayoutContainer
