import React, { useContext, useCallback } from 'react'
import Proptypes from 'prop-types'
import { createLocaleTextGetter } from '../lib/create-locale' // Or wherever you stashed it

const localize = (Component, { propsTotranslate = ['data'] } = {}) => props => {
  const locale = props.pageContext?.locale || props.locale
  const getLocalizedContent = useCallback(createLocaleTextGetter(locale))
  const translatedProps = {}
  propsTotranslate.forEach(key => {
    translatedProps[key] = getLocalizedContent(props[key])
  })
  return <Component {...{ ...props, ...translatedProps }} />
}
export default localize
